.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.nav-item {
    a:hover {
        cursor: pointer;
    }
}

.navbar-light {
    .navbar-nav {
        .nav-link {
            color: '#222222';
        }
        .nav-link.active {
            color: '#ff6400';
            font-weight: 500;
            border-bottom: 1px solid #ff6400;
        }
    }
}

.main-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    footer: {
        flex-shrink: 0;
    }
}

/*content*/
.content {
    flex: 1 0 auto;
}

/*footer*/
.footer {
    background-color: #fafafa;
    margin-top: '10px';
    padding: 3rem 1.5rem 1.5rem;
}
